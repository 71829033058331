import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { HContainer, Layout, Seo, TextGroup } from "components/common"
import Footer from "components/navigation/Footer"

// import { FeaturedBlogPost } from "src/sections/blog"
// import { PageTitleAndNewsLetter } from "src/components/blog"
import { Posts } from "src/components/post"

import Nav from "components/navigation/Nav"
import MobileNav from "src/components/navigation/mobile/MobileNav"

import BottomNewsletterCta from "src/components/common/shared/BottomNewsletterCta"

// import { NewsPosts } from "src/components/news"

// import NewsLetterForm from "components/forms/NewsLetterForm"

import { device, useContentfulSection, useMeta } from "utils"

const BlogPage = ({ data: { blog, blogPosts, bottom_cta } }) => {
  const { blog_title } = useContentfulSection(blog.sections)

  const meta = useMeta(blog)
  const posts = {
    edges: [...blogPosts.edges].sort(
      (a, b) => (new Date(a.node.date)).valueOf() - (new Date(b.node.date)).valueOf()
    )
  };

  return (
    <Layout>
      <Seo {...meta} />

      <Nav />
      <MobileNav />

      <Wrap>
        <HContainer className="blog-container">
          <TextGroup
            className="blog-title"
            title={blog_title.title}
            titleAs="h1"
            subtitle={blog_title.subtitle}
          />

          {/* posts */}
          <Posts posts={posts} type="blog" />

          <BottomNewsletterCta {...bottom_cta} />
        </HContainer>
      </Wrap>
      <Footer />
    </Layout>
  )
}

const Wrap = styled.div`
  position: relative;
  z-index: 1;

  .blog-title {
    --tg-text-align: center;
  }

  .blog-container {
    /* --section-max-width: 1080px; */
    --section-max-width: 925px;
    padding-top: var(--sp-mobile-nav);
    padding-bottom: var(--sp-section-mobile);

    @media ${device.laptop} {
      padding-top: var(--sp-nav);
      padding-bottom: var(--sp-section);
    }

    .posts {
      margin-top: var(--sp-64);
    }
  }
`

export const query = graphql`
  {
    blog: contentfulPage(pageId: { eq: "blog" }) {
      ...Page
    }
    blogPosts: allContentfulBlogPost(
      filter: { slug: { ne: "placeholder" } }
      sort: { order: DESC, fields: date } #   limit: 9
    ) {
      edges {
        node {
          ...BlogPost
        }
      }
    }
    bottom_cta: contentfulSection(sectionId: { eq: "bottom_newsletter_cta" }) {
      ...Section
    }
  }
`

export default BlogPage
